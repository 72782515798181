//import { Alert } from "bootstrap";

(function() {
  var darkSwitch = document.getElementById("darkSwitch");
  if (darkSwitch) {
    initTheme();
    darkSwitch.addEventListener("change", function(event) {
      resetTheme();
    });
    function initTheme() {
      var ThemeSelected = localStorage.getItem("darkSwitch");
      if(ThemeSelected==null){
        
        var user_theme = null;
        
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
          user_theme = 'dark';
        }else{
          user_theme = 'light';
        }
        document.body.setAttribute("data-bs-theme",user_theme);
        localStorage.setItem("darkSwitch",user_theme);
        
        
      }else{
        document.body.setAttribute("data-bs-theme",ThemeSelected);
        localStorage.setItem("darkSwitch",ThemeSelected);
      }
      if(document.body.getAttribute("data-bs-theme") == 'dark'){
        darkSwitch.checked = true;
      }
      
      
        
      // darkSwitch.checked = ThemeSelected;
      
      // ThemeSelected
      //   ? document.body.setAttribute("data-bs-theme", "dark")
      //   : document.body.setAttribute("data-bs-theme","");
    }
    function resetTheme() {
      if (darkSwitch.checked) {
        document.body.setAttribute("data-bs-theme", "dark");
        localStorage.setItem("darkSwitch", "dark");
      } else {
        document.body.setAttribute("data-bs-theme",'light');
        localStorage.setItem("darkSwitch",'light');
      }
    }
  }else{
   
    

  }
})();