//import { DisplayLabel } from './components/DisplayLabel';

//import 'bootstrap';
import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;
// import 'dark-mode-switch';
//import '/css/test.scss';

//alert('hoezo kappen hij doet yolo!!!');
//Import our custom CSS 
//import '../../css/src/main.scss';

import './dark-mode-switch.js';

// import Headroom from "headroom.js";

// // select your header or whatever element you wish
// const header = document.querySelector("#main-nav");

// const headroom = new Headroom(header);
// headroom.init();

// import rolly from 'rolly.js';
import Rellax from "rellax";
var rellax = new Rellax('.rellax', {
  horizontal: true,
 // breakpoints: [576, 768, 1201]
  
});

// import rolly from 'rolly.js';

// const r = rolly({
//   view: document.querySelector('.home'),
//   native: true,
//   // other options
// });
// r.init();




//https://swiperjs.com/get-started
//import Swiper from 'swiper';
  // import Swiper styles
  //import 'swiper/css';

  //const swiper = new Swiper(...);

//import Masonry from 'masonry-layout';

//import barba from '@barba/core';

//import gsap from "gsap";

//npm install flickity-imagesloadedimport PhotoSwipeLightbox from 'photoswipe/lightbox';

//https://www.lightgalleryjs.com/
// import lightGallery from 'lightgallery';

// // Plugins
// import lgThumbnail from 'lightgallery/plugins/thumbnail';
// import lgZoom from 'lightgallery/plugins/zoom';

//import { tns } from "./node_modules/tiny-slider/src/tiny-slider"


//var msnry = new Masonry( '.grid', {});

// let Main = {
//   init: async function () {

//     // initialize demo javascript component - async/await invokes some 
//     //  level of babel transformation
//     const displayLabel = new DisplayLabel();
//     await displayLabel.init();

//   }
// };

// barba.init({
//   transitions: [{
//     name: 'default-transition',
//     leave() {
//       // create your stunning leave animation here
//       // console.log('Leave');
//       // document.body.classList.add("fade-out");
//     },
//     enter() {
//       // create your amazing enter animation here
//       // console.log('Enter');
//       // document.body.classList.add("fade-out-enter");
//       // st = setTimeout(function(){
//       //   document.body.classList.remove("fade-out-enter","fade-out");
//       // },1300);
      
//     }
//   }]
// });






// playBtn.addEventListener('mouseleave', function () {
//   heartbeat.pause();
//   heartbeat.currentTime = 0;
// }, false);
// playBtn1.addEventListener('mouseleave', function () {
//   heartbeat.pause();
//   heartbeat.currentTime = 0;
// }, false);

// playBtn2.addEventListener('mouseleave', function () {
//   heartbeat.pause();
//   heartbeat.currentTime = 0;
// }, false);

// resetBtn.addEventListener('mouseover', function () {
//   heartbeat.play();
// }, false);

// resetBtn.addEventListener('mouseleave', function () {
//   heartbeat.pause();
//   heartbeat.currentTime = 0;
// }, false);



// myOffcanvas.addEventListener('hidden.bs.offcanvas', event => {
//   alert('boe');
// })

// barba.init({
//   timeout: 10000,
//   requestError: (trigger, action, url, response) => {
//     // go to a custom 404 page if the user click on a link that return a 404 response status
//     if (action === 'click' && response.status && response.status === 404) {
//       barba.go('/404');
//     }

//     // prevent Barba from redirecting the user to the requested URL
//     // this is equivalent to e.preventDefault() in this context
//     return false;
//   },
//   transitions: [{
    
//     sync: true,
//     name: 'opacity-transition-yolo',
//     leave(data) {
//       return gsap.to(data.current.container, {x: 0, y:0, duration: .25, opacity:0});
//     },
//     enter(data) {
//       return gsap.from(data.next.container, {x: 0, y:0, duration: .4, opacity:0.4});
//     }
//   }]
// });


// barba.hooks.afterEnter((data) => {
//   //console.log(data.next.namespace);
//   //alert('console.log(data.next.namespace);');
//   var Pnav = document.getElementsByClassName('single-post-nav-wr')[0];
//   var PnavHtml = Pnav.innerHTML;
//   var Cnav = document.getElementsByClassName('outer-barba')[0];
//   Cnav.innerHTML = '';
//   Pnav.innerHTML = '';
//   Pnav.remove();
//   // console.log(Pnav);
//   // console.log('!');
//   Cnav.innerHTML = PnavHtml;

 

// });






var Flickity = require('flickity');
require('flickity-imagesloaded');
require('flickity-fullscreen');

//Main.init();


function elementExists(ele) {
  if (typeof (ele) != 'undefined' && ele != null) {
    return true;
  }
}
const observer = new IntersectionObserver(
  ([e]) => e.target.classList.toggle("is-pinned", e.intersectionRatio < 1),
  { threshold: [1] }
);




const el = document.querySelector(".aanmelden-block");
if (elementExists(el)) {
  observer.observe(el);
}




// const el1 = document.querySelector(".sticky-feature-1");
// if (elementExists(el1)) {
//   observer.observe(el1);
// }

document.addEventListener("DOMContentLoaded", function() {
  const body = document.querySelector("body");
  
  // bootstrap dropdown active
  const Mclass = document.querySelector(".current-menu-ancestor");
  if (elementExists(Mclass)) {
    
    var Mdropdown = Mclass.querySelector(".dropdown-menu");
    if (elementExists(Mdropdown)) {
      Mdropdown.classList.add("show");
    }
  }
  var st = setTimeout(function () {
    body.classList.add("first-load");
    //alert('!');
  },100);
  document.querySelectorAll("#wpadminbar a").forEach(item=>item.setAttribute('data-barba-prevent','self'));
  var myOffcanvas = document.getElementById('navbarOffcanvasMd')
  var bsOffcanvas = new bootstrap.Offcanvas(myOffcanvas);

  // const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
  // const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl1 =>
  // {
  //   new bootstrap.Tooltip(tooltipTriggerEl1);
  //   alert('!');
  // })

  // Enabling all the tooltips on the page.
  const tooltipElements =
    document.querySelectorAll('[data-bs-toggle="tooltip"]');
  const tooltips =
    [...tooltipElements].map(el => new bootstrap.Tooltip(el));

  
  var open_tooltip = null;
  //console.log(tooltipElements);
  [...tooltipElements].forEach((tooltipElements) => {
    tooltipElements.addEventListener('shown.bs.tooltip', (ele) => {
      open_tooltip = ele.target.id;
    });
    tooltipElements.addEventListener('show.bs.tooltip', (ele) => {
      if (open_tooltip) {
        //alert(open_tooltip);
        var tt = '#' + open_tooltip;
        var t = bootstrap.Tooltip.getInstance(tt);
        t.hide();
      }
    });
  });

  // document.getElementById("trigger").addEventListener('click', () => {
  //   bootstrap.Tooltip.getInstance("#gfg").show();
  //   setTimeout(() => {
  //     bootstrap.Tooltip.getInstance("#gfg").hide();
  //   }, 5000);
  // });




  
  var windowWidth = window.innerWidth;
  //console.log(windowWidth);
  // if(windowWidth <= 767){
  
  //   const cbox = document.querySelectorAll(".menu-item");

  //   for (let i = 0; i < cbox.length; i++) {
  //     cbox[i].addEventListener("mousedown", function(){
  //       bsOffcanvas.toggle();
  //     });
  //   }

  // }

  // var menuitem = document.querySelectorAll('.menu-item');
  // menuitem.addEventListener("mousedown", function(){
  //   alert('!');
  // });
  
  const highlightedItems = document.querySelectorAll(".portfolio-item");
 
  var st = setTimeout(function(){
    highlightedItems.forEach((userItem) => {
      userItem.classList.remove("hide-car");
    });
  },500);
});

//console.log(process.env.NODE_ENV);
if (process.env.NODE_ENV !== 'production') {
  console.log('Looks like we are in development mode');
  const body = document.querySelector("body");
  body.classList.add("debug");
}else{
  //console.log('Looks like we are in production mode');
}
 